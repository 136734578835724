import React, { useEffect, useState } from "react";

const Newsletters = ({ module }) => {

    const [newsletters, setNewsletters] = useState(null);
    
    useEffect(() => {
        fetch('https://wp.tasafaris.com/wp-json/newsletterapi/v1/links')
        .then(response => response.json())
        .then(data => {
          // Process the retrieved data here
          setNewsletters(data);
        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });
    }, []);

  return (
    <section className={`tnc-content lg:pt-60 lgscreen:mt-30 pt-0 lg:pb-120 pb-30 ${module?.extraClass ? " " + module.extraClass : ""}`}>
      <div className='w-[850px] m-auto text-center lgscreen:w-full px-20'>
        <div className="content fade-ani text-left">
        {newsletters?.map((item, i) => (
        <div key={item.url}>
        <a href={item.url} target="_blank">
            <p>{item.title}</p>
        </a>
        </div>
        ))}
        </div>
      </div>
    </section>
  )
}

export default Newsletters;